// import { configAxios } from './apiConfig';
import logger from 'utils/logger/pinoConfig';

export const createLog = (error) => {
  try {
    logger.error(
      {
        message: error.message,
        config: error.config,
        response: error.response,
        stack: error.stack,
      },
      'Request Error'
    );
    return null;
  } catch (err) {
    return null;
  }
};
