export const SectionTitle = {
  color: (theme) => theme.palette.background.primary.dark,
  fontWeight: 700,
  maxWidth: { xs: '920px', xl: '1100px' },
  mb: { xs: 5, sm: 8 },
  mx: 'auto',
  px: { xs: 4 },
  textAlign: 'center',
};

export const SectionWrapper = {
  px: { xs: 0, sm: 4, md: 8, lg: 14, xl: 27 },
  py: { xs: 5, md: 8 },
};
