export const DefaultMenuNav = [
  {
    href: '/ingles-online',
    text: 'Estudar inglês online',
    isAnchor: false,
  },
  {
    href: '/intercambio',
    text: 'Fazer intercâmbio',
    isAnchor: false,
  },
  {
    href: '/corporate',
    text: 'Para sua empresa',
    isAnchor: false,
  },
];

export const DefaultTabs = [
  {
    href: '/ingles-online',
    text: 'Inglês online',
  },
  {
    href: '/intercambio',
    text: 'Intercâmbio',
  },
  {
    href: '/corporate',
    text: 'Para empresas',
  },
];

export const AccountDefaultMenuNav = [
  {
    href: '/ingles-online',
    text: 'Ver planos',
    isAnchor: false,
  },
  {
    href: '/intercambio',
    text: 'Cotar intercâmbio',
    isAnchor: false,
  },
  {
    href: '/corporate',
    text: 'Para sua empresa',
    isAnchor: false,
  },
];

export const AccountHeaderMenuNav = [
  {
    href: '/account',
    text: 'Início',
    isAnchor: false,
  },
  {
    href: '/account/courses',
    text: 'Meus cursos',
    isAnchor: false,
  },
  {
    href: '/account/group-talk',
    text: 'Conversação em grupo',
    isAnchor: false,
  },
  {
    href: '/account/private-talk',
    text: 'Aulas particulares',
    isAnchor: false,
  },
];

export const HomeMenuNav = [
  {
    href: '#how-it-works',
    text: 'Como funciona',
    isAnchor: true,
  },
  {
    href: '#for-you',
    text: 'Para você',
    isAnchor: true,
  },
  {
    href: '#for-companies',
    text: 'Para sua empresa',
    isAnchor: true,
  },
  // {
  //   href: '#for-children',
  //   text: 'Para crianças',
  //   isAnchor: true,
  // },
];

export const AbroadMenuNav = [
  {
    href: '/ingles-online',
    text: 'Estudar inglês online',
    isAnchor: false,
  },
  {
    href: '#destiny-guide',
    text: 'Guia de destinos',
    isAnchor: true,
    isSelect: true,
    subLinks: [
      {
        text: 'África do Sul',
        href: '/intercambio/africa-do-sul',
      },
      {
        text: 'Austrália',
        href: '/intercambio/australia',
      },
      {
        text: 'Canadá',
        href: '/intercambio/canada',
      },
      {
        text: 'Escócia',
        href: '/intercambio/escocia',
      },
      {
        text: 'Estados Unidos',
        href: '/intercambio/estados-unidos',
      },
      {
        text: 'Inglaterra',
        href: '/intercambio/inglaterra',
      },
      {
        text: 'Irlanda',
        href: '/intercambio/irlanda',
      },
      {
        text: 'Malta',
        href: '/intercambio/malta',
      },
      {
        text: 'Nova Zelândia',
        href: '/intercambio/nova-zelandia',
      },
    ],
  },
  {
    href: '/intercambio',
    text: 'Fazer intercâmbio',
    isAnchor: false,
  },
  {
    href: '/corporate',
    text: 'Para sua empresa',
    isAnchor: false,
  },
];

export const HomeTabs = [
  {
    href: '#for-you',
    text: 'Para você',
    isAnchor: true,
  },
  {
    href: '/corporate',
    text: 'Para sua empresa',
    isAnchor: false,
  },
];

export const CorporateMenuNav = [
  {
    href: '#how-it-works',
    text: 'Como funciona',
    isAnchor: true,
  },
  {
    href: '/#for-you',
    text: 'Para você',
    isAnchor: false,
  },
  {
    href: '#for-companies',
    text: 'Para sua empresa',
    isAnchor: true,
  },
];

export const CorporateTabs = [
  {
    href: '/#for-you',
    text: 'Para você',
    isAnchor: false,
  },
  {
    href: '#for-companies',
    text: 'Para sua empresa',
    isAnchor: true,
  },
];

export const LicenseMenuNav = [
  {
    href: '/account/licenses',
    text: 'Gestão de licenças',
    isAnchor: false,
  },
  {
    href: '/account/dashboard',
    text: 'Relatórios',
    isAnchor: false,
  },
  {
    href: '/account?suporte=true',
    text: 'Suporte',
    isAnchor: false,
  },
];

export const PlansTabs = [
  {
    href: '/intercambio',
    text: 'Fazer intercâmbio',
    isAnchor: false,
  },
  {
    href: '/corporate',
    text: 'Para sua empresa',
    isAnchor: false,
  },
];

export const AbroadTabs = [
  {
    href: '/ingles-online',
    text: 'Estudar inglês online',
    isAnchor: false,
  },
  {
    href: '/corporate',
    text: 'Para sua empresa',
    isAnchor: false,
  },
];
