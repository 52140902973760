import { useState } from 'react';
import PropTypes from 'prop-types';
import Dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useUser } from '@auth0/nextjs-auth0/client';
import { getCookie } from 'cookies-next';
import { useQuery } from '@tanstack/react-query';
import {
  AppBar,
  Box,
  Skeleton,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import {
  DefaultMenuNav,
  DefaultTabs,
  AccountDefaultMenuNav,
} from 'mocks/menuNav';

import { getPermission } from 'services/middleware/auth';

import { isSalesExecutiveSession } from 'utils/auth/authHelper';

const Logo = Dynamic(() => import('components/Logo'), {
  ssr: false,
  loading: () => <Skeleton variant="rectangular" width={160} height={40} />,
});

const SideMenuComponent = Dynamic(
  () => import('components/Shared/Header/SideMenu'),
  {
    ssr: false,
    loading: () => <Skeleton variant="rectangular" width={580} height={54} />,
  }
);

const TableTabs = Dynamic(() => import('components/Shared/Header/Tabs'), {
  ssr: false,
  loading: () => (
    <Box display="flex" flexDirection="row" gap={3} px={3}>
      <Skeleton height={48} width="100%" />

      <Skeleton height={48} width="100%" />
    </Box>
  ),
});

const ExchangeRateModalComponent = Dynamic(
  () =>
    import(
      'components/SalesExecutivesComponents/ExchangeRateModalComponent/ModalComponent'
    ),
  { ssr: false }
);

Header.propTypes = {
  logoLink: PropTypes.string,
  menuNavHeader: PropTypes.instanceOf(Array),
  menuNavSideMenu: PropTypes.instanceOf(Array),
  showMenu: PropTypes.bool,
  showTabs: PropTypes.bool,
  tabs: PropTypes.instanceOf(Array),
};

export default function Header({
  logoLink = '/',
  menuNavHeader = DefaultMenuNav,
  menuNavSideMenu = AccountDefaultMenuNav,
  showMenu = true,
  showTabs = true,
  tabs = DefaultTabs,
}) {
  const { user } = useUser();
  const theme = useTheme();
  const router = useRouter();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const isSalesExecutives = user ? isSalesExecutiveSession(user) : false;
  const [openModal, setOpenModal] = useState(false);

  const { data: isTalker } = useQuery({
    queryKey: ['isTalker'],
    queryFn: () => getPermission('talker'),
    refetchOnWindowFocus: false,
    enabled: !!user,
  });

  const { data: isMeetingManager } = useQuery({
    queryKey: ['isMeetingManager'],
    queryFn: () => getPermission('meetingManager'),
    refetchOnWindowFocus: false,
    initialData: false,
    enabled: !!user,
  });

  const { data: isLicenseManager } = useQuery({
    queryKey: ['licenseManager'],
    queryFn: () => getPermission('licenses'),
    refetchOnWindowFocus: false,
    initialData: false,
    enabled: !!user,
  });

  const { data: isGroupManager } = useQuery({
    queryKey: ['groupManager'],
    queryFn: () => getPermission('groupManager'),
    refetchOnWindowFocus: false,
    initialData: false,
    enabled: !!user,
  });

  const { data: isStudentManager } = useQuery({
    queryKey: ['studentManager'],
    queryFn: () => getPermission('studentEngagementView'),
    refetchOnWindowFocus: false,
    initialData: false,
    enabled: !!user,
  });

  const { data: isTeacher } = useQuery({
    queryKey: ['studentManager'],
    queryFn: () => getPermission('studentEngagementView'),
    refetchOnWindowFocus: false,
    initialData: false,
    enabled: !!user,
  });

  const { data: isKids } = useQuery({
    queryKey: ['eduBasicKid'],
    queryFn: () => getPermission('kids'),
    refetchOnWindowFocus: false,
    initialData: false,
    enabled: !!user,
  });

  const { data: isCourseManager } = useQuery({
    queryKey: ['courseManager'],
    queryFn: () => getPermission('courseManager'),
    refetchOnWindowFocus: false,
    initialData: false,
    enabled: !!user,
  });

  const runningInWebViewCookie = getCookie('runningInWebView', {});

  const isRunningInWebView = runningInWebViewCookie?.toLowerCase() === 'true';

  const edubasicRegex = /EduBasic\/Auth/;
  const isEdubasicLogin = edubasicRegex.test(router.pathname);

  const permissions = {
    isCourseManager,
    isGroupManager,
    isKids,
    isLicenseManager,
    isMeetingManager,
    isSalesExecutives,
    isStudentManager,
    isTalker,
    isTeacher,
  };

  return (
    <AppBar
      id="header"
      position="sticky"
      color="inherit"
      elevation={1}
      sx={{ pb: 0.5 }}
    >
      {mobileBreakpoint &&
        !isKids &&
        !isEdubasicLogin &&
        !isRunningInWebView &&
        showTabs && <TableTabs tabs={tabs} />}

      <Toolbar>
        <Box
          alignItems="center"
          display="flex"
          flexGrow={1}
          justifyContent="space-between"
        >
          <Logo link={logoLink} logoType="minimalOnMobile" />

          {showMenu && (
            <>
              <Box>
                <SideMenuComponent
                  menuNavHeader={menuNavHeader}
                  menuNavSideMenu={menuNavSideMenu}
                  permissions={permissions}
                  setOpenModal={setOpenModal}
                  user={user}
                />
              </Box>

              {isSalesExecutives && (
                <ExchangeRateModalComponent
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                />
              )}
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
