import axios from 'axios';
import { getAccessToken } from '@auth0/nextjs-auth0';

import { createLog } from 'services/logService';

export const configAxios = axios.create({
  baseURL: process.env.FP_API,
  responseType: 'application/json',
});

export const middlewareCall = axios.create({
  baseURL: '/api/',
  responseType: 'application/json',
});

export const getClientAuthenticatedConfig = async (accessToken) => {
  const client = axios.create({
    baseURL: process.env.FP_API,
    responseType: 'application/json',
    headers: { authorization: `Bearer ${accessToken}` },
  });

  return client;
};

export const getAuth0AxiosConfig = async (req, res) => {
  const accessToken = await getAuthAccessToken(req, res);

  const client = axios.create({
    baseURL: process.env.AUTH0_ISSUER_BASE_URL,
    responseType: 'application/json',
    headers: { authorization: `Bearer ${accessToken}` },
  });

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        res.writeHead(302, { Location: '/account/expired' });
        res.end();
        return;
      }
      Promise.reject(error);
    }
  );

  return client;
};

export const getAxiosConfig = async (req, res) => {
  const accessToken = await getAuthAccessToken(req, res);

  const client = axios.create({
    baseURL: process.env.FP_API,
    responseType: 'application/json',
    headers: { authorization: `Bearer ${accessToken}` },
  });

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        res.writeHead(302, { Location: '/account/expired' });
        res.end();
        return;
      }

      if (error.response?.status >= 400) {
        res.writeHead(302, { Location: '/500' });
        res.end();
        return;
      }

      Promise.reject(error);
    }
  );

  return client;
};

export const getAuditedAxiosConfig = async (req, res) => {
  const accessToken = await getAuthAccessToken(req, res);

  let url = process.env.FP_API;
  url = `${url.substring(0, url.length - 4)}/audited-api`;

  const client = axios.create({
    baseURL: url,
    responseType: 'application/json',
    headers: { authorization: `Bearer ${accessToken}` },
  });

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        res.writeHead(302, { Location: '/account/expired' });
        res.end();
        return;
      }
      Promise.reject(error);
    }
  );

  return client;
};

export const getAuthAccessToken = async (req, res) => {
  try {
    const AccessProvider = await getAccessToken(req, res);

    return AccessProvider?.accessToken || null;
  } catch (error) {
    createLog(error);

    if (
      error.code === 'ERR_FAILED_REFRESH_GRANT' ||
      error.response?.status === 401
    ) {
      res.writeHead(302, { Location: '/account/expired' });

      res.end();

      return;
    }

    res.writeHead(302, { Location: '/500' });
  }
};

export const getCrmVisitorAxiosConfig = async () => {
  const accessToken = process.env.HUBSPOT_VISITOR_ACCESS_TOKEN;
  const url = process.env.HUBSPOT_API;
  const client = axios.create({
    baseURL: url,
    responseType: 'application/json',
    headers: { authorization: `Bearer ${accessToken}` },
  });

  return client;
};
