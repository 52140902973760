import { getSession } from '@auth0/nextjs-auth0';
import { createLog } from 'services/logService';

// Podemos refazer essa parte utilizando o recurso de middleware e resgatando os dados do backend
export const getSalesExecutiveCode = (user) => {
  if (user) {
    return user.expert;
  }

  return null;
};

// Podemos refazer essa parte utilizando o recurso de middleware e resgatando os dados do backend
export const isSalesExecutiveSession = (user) => user.expert && true;

export const getRoles = async (req, res) => {
  let roles = [];

  try {
    const session = await getSession(req, res);

    if (session) roles = parseJwt(session.accessToken).realm_access.roles;
  } catch (e) {
    createLog(e);
  }

  return roles;
};

export const getAllClaims = async (req, res) => {
  try {
    const session = await getSession(req, res);
    return parseJwt(session.accessToken);
  } catch (e) {
    createLog(e);
  }

  return null;
};

export const hasPermissionByRole = async (req, res, permission) => {
  let hasPermission = false;

  try {
    const roles = await getRoles(req, res);
    hasPermission = roles.some((scope) => scope === permission);
  } catch (error) {
    return createLog(error);
  }

  return hasPermission;
};

export const hasPermissionByRoles = async (req, res, permissions) => {
  const hasPermission = {};

  try {
    const roles = await getRoles(req, res);

    roles.forEach((element) => {
      if (permissions.includes(element)) {
        hasPermission[element] = true;
      }
    });
  } catch (error) {
    return createLog(error);
  }

  return hasPermission;
};

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export function getRedirectUri(req) {
  return `${getCurrentHost(req)}/account`;
}

export function getCurrentHost(req) {
  return `${new URL(process.env.AUTH0_BASE_URL).protocol}//${
    req.headers.host
  }/`;
}
