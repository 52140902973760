const cloudinaryLoader = ({
  src,
  resize = 'c_limit',
  width,
  quality = 'auto',
}) => {
  const baseParams = `https://res.cloudinary.com/intercambiodireto-com/f_auto,q_${
    quality || 75
  }`;
  const widthAndResizeParams = width ? `,${resize},w_${width}` : '';
  return `${baseParams}${widthAndResizeParams}/${src}`;
};

export default cloudinaryLoader;
