export const testimonies = [
  {
    name: 'Ana Assis',
    img: '/Depoimentos/anajuh_byomtz',
    order: 4,
    testimony:
      'Desde o primeiro atendimento eles foram super solícitos e simpáticos. Precisei remarcar meu intercâmbio por conta da pandemia e eles fizeram de tudo pra me ajudar e facilitar minha vida. Recomendo demais a empresa e o curso da Fluencypass! Todos os colaboradores são muito atenciosos e amigáveis! Faço aula quase todos os dias e já sinto a equipe da fluency como amigos kkk.',
  },
  {
    name: 'Gabriel da Silva',
    order: 3,
    testimony:
      'Atualmente estou fazendo o curso, e adoro o fato de ter muitas conversas em inglês e até aulas particulares, para dá aquele empurrão para os que tem vergonha de falar em publico, os professores e todos os outros funcionários  me trataram muito bem, sendo muito prestativos e atenciosos, eu recomendo está sendo uma ótima experiência.',
  },
  {
    name: 'Leonardo Dias',
    img: '/Depoimentos/leonardo_sh9wry',
    order: 2,
    testimony:
      'Durante a minha procura por cursos de inglês, acredito que foi muita sorte ter encontrado a Fluencypass. Gostei bastante do conteúdo do curso online, das aulas e das conversações em grupo. Agradeço a todos os teachers pelo progresso do meu inglês e recomendo para todos a Fluencypass!',
  },
  {
    name: 'Carlos Sager',
    img: '/Depoimentos/carloseduardosager_xetjtu',
    order: 1,
    testimony:
      'Com uma equipe atenciosa, a Fluencypass é uma excelente plataforma para aprender inglês com aulas focadas em situações de nosso cotidiano. Conteúdos interativos como os flashcards, gramática prática e ágil e aulas em grupo e particulares, com professores atenciosos e capacitados, para conversação. Uma excelente experiência para deslanchar o seu inglês. Super recomendo!',
  },
  {
    name: 'Luis Maier',
    order: 5,
    testimony:
      'Excelente atendimento, desde o primeiro contato foram atenciosos, explicam bem os planos e facilitam para poderem ter acesso o inglês. Sem contar o método que desde o começo te auxilia no atingimento da sua meta. Excelente escola super recomendo.',
  },
  {
    name: 'Vanessa Ormonde',
    img: '/Depoimentos/vanessa-ormonde_te1dqe',
    order: 5,
    testimony:
      'Minha experiência foi e está sendo ótima, desde os atendentes na hora da compra e depois com quaisquer dúvidas que tive! A plataforma é super fácil de navegar e a didática é bem completa! As aulas de conversação em grupo ajudam demais a desenvolver a falar e escutar! Recomendo com toda certeza pra quem procura a fluência no inglês!',
  },
  {
    name: 'Tiago Cabral',
    img: '/Depoimentos/tiago-cabral_o4jooo',
    order: 5,
    testimony:
      'Depois de passar alguns meses em outros cursos, finalmente consegui encontrar um que atende todas as minhas expectativas. Consigo adequar minha agenda com às aulas, a plataforma de ensino é muito dinâmica e divertida. Tenho aprendido bastante com as aulas de conversão, os professores são muito didáticos. Recomendo sempre!',
  },
  {
    name: 'Mary Helen',
    img: '/Depoimentos/mary-helen_fouc1g',
    order: 5,
    testimony:
      'Eu sabia que queria fazer um curso de inglês, mas queria de algo que fugisse do tradicional e que me permitisse evoluir de uma forma leve, pesquisei no Google e a fluencypass apareceu e logo depois a Tainá entrou em contato e não sei dizer o quanto me senti conectado com o curso pela forma com que a Tainá fez o atendimento, hoje já matriculada tenho o Paulo que me ajuda em todas as minhas dúvidas e não me deixa perdida. Estou amando cada vez mais. Obrigada fluencypass.',
  },
  {
    name: 'Thaís Nascimento',
    img: '/Depoimentos/thais-nascimento_dllhyd',
    order: 5,
    testimony:
      'A Fluencypass me surpreendeu muito! Eu já estudava em uma escola de Inglês, na modalidade presencial, mas, sentia muita dificuldade na conversação. Não conseguia evoluir. Busquei uma escola online com foco na conversação como um plus e encontrei a Fluencypass. Já havia pesquisado outras escolas, que são mais conhecidas, entretanto, desde o atendimento ao telefone, eu já fiquei encantada pela Fluencypass. A atendente foi excelente...não me deixou dúvidas de que eu daria meu voto de confiança à essa empresa. O site é bem intuitivo e tem um formato que eu gosto e as aulas de conversação, em grupo e individuais, são sensacionais. O professor Tiago é perfeito! Conduz as aulas com uma didática muito boa e que possibilita uma melhora no Inglês de fato. Com menos de um mês de assinatura, eu já tô sentindo um destravar na língua Inglesa e estou perdendo o medo de falar. Creio que voltarei em breve para testemunhar a minha fluência! 😍',
  },
  {
    name: 'André Vieira',
    img: '/Depoimentos/andre-vieira_zmecha',
    order: 5,
    testimony:
      'Estou cursando a Fluencypass há uns 5 meses e até agora a experiência têm sido incrível! Tudo é detalhadamente pensado para que o aluno tenha uma imersão e uma rota de aprendizado constante e eficiente. Tenho gostado demais dos conteúdos, da maneira como é incentivada a fala pelos grupos de conversação e principalmente do suporte que foi extremamente solícito em tudo que precisei até hoje. Já indiquei para todos os meus amigos e continuo indicando porque em mim mesmo eu vejo uma transformação no que é referente à língua inglesa. Sou muito grato por ter encontrado esse curso que me possibilita aprender muito em pouco tempo. Sei que muito em breve estarei alcançando meus objetivos profissionais por conta do ótimo serviço da Fluencypass.',
  },
  {
    name: 'Heloisa Pestana',
    img: '/Depoimentos/heloisa-pestana_y9holi',
    order: 5,
    testimony:
      'A fluencypass é uma escola que te desenvolve não só na gramática mas na parte oral tendo a oportunidade de praticar inglês com outras pessoas e com professor partícular todos os dias. Além de trabalhar a metodologia por repetição tbem e a oportunidade de aprender inglês em 1 ano.',
  },
  {
    name: 'Lucas Rosa',
    order: 5,
    testimony:
      'Os professores são ótimos. O método é muito bom e a plataforma dinâmica e intuitiva. Estou gostando muito do curso.',
  },
];

export const videoTestimonies = [
  {
    name: 'Guilherme',
    age: 31,
    profession: 'Engenheiro Químico',
    country: 'Malta',
    img: '/Depoimentos/depoimento_1_ke4gg4',
    link: 'https://www.youtube.com/watch?v=RdpMUBGQxKQ&list=PLzd_RwJsq_hGrnUujcqSmg0oRS5omduQy&index=5',
  },
  {
    name: 'Camila',
    age: 28,
    profession: 'Publicitária',
    country: 'Malta',
    img: '/Depoimentos/depoimento_4_eru3c1',
    link: 'https://www.youtube.com/watch?v=1ZBqcjDIfxI&list=PLzd_RwJsq_hGrnUujcqSmg0oRS5omduQy&index=9',
  },
  {
    name: 'Kaionara',
    age: 32,
    profession: 'Designer de interiores',
    country: 'Malta',
    img: '/Depoimentos/depoimento_3_ow7bcq',
    link: 'https://www.youtube.com/watch?v=KA2OrJdXdnY&list=PLzd_RwJsq_hGrnUujcqSmg0oRS5omduQy&index=10',
  },
  {
    name: 'Paulo',
    age: 33,
    profession: 'Jornalista',
    country: 'Irlanda',
    img: '/Depoimentos/depoimento_2_umnpeu',
    link: 'https://www.youtube.com/watch?v=g7gSPTxELkU&list=PLzd_RwJsq_hGrnUujcqSmg0oRS5omduQy&index=11',
  },
];

export const testimoniesAbroad = [
  {
    text: 'Eu sempre tive o sonho de realizar o intercâmbio e estava procurando por uma agência que me desse uma direção [...] na procura encontrei a Fluencypass super indico, você fecha o pacote e não se preocupa com mais nada.',
    name: 'Hellen Guimarães',
    age: 26,
    profession: 'Engenheira civil',
    product: 'Intercâmbio em Malta',
    videoURL: 'https://player.vimeo.com/video/838676183?h=8f559aeeaa',
    thumbURL:
      'https://res.cloudinary.com/intercambiodireto-com/image/upload/q_auto,f_auto/o_100,b_black/c_lfill,o_60,co_rgb:ffffff,b_rgb:000000/v1688160669/Others/Depoimentos/hellen_guimaraes_nul816',
  },
  {
    text: 'Eu tinha uma ansiedade no inglês, mas não sabia por onde começar e onde ir [...] Eu busquei a Fluencypass, e me encaixaram perfeitamente no lugar onde eu deveria fazer meu intercâmbio. Foi uma escolha fantástica, Cape Town é incrível!',
    name: 'Jonathan Santana',
    age: 36,
    profession: 'Gerente Comercial',
    product: 'Intercâmbio em Cape Town',
    videoURL: 'https://player.vimeo.com/video/838676118?h=180737dc86',
    thumbURL:
      'https://res.cloudinary.com/intercambiodireto-com/image/upload/q_auto,f_auto/o_100,b_black/c_lfill,o_60,co_rgb:ffffff,b_rgb:000000/v1688160668/Others/Depoimentos/jonathan_santana_zikrpm',
  },
  {
    text: 'Eu fiz dois intercâmbios com a Fluencypass, o primeiro em 2018 para Cape Town [...] foi uma experiência tão incrível que eu quis fazer um outro intercâmbio em 2022 para o Canadá. Foi uma escolha que eu fiz que eu faria de novo, e se pudesse eu escolheria a Fluencypass de novo.',
    name: 'Mayla Barbirato',
    age: 35,
    profession: 'Bióloga',
    product: 'Intercâmbio em Cape Town e Canadá',
    videoURL: 'https://player.vimeo.com/video/838676058?h=514dd5b209',
    thumbURL:
      'https://res.cloudinary.com/intercambiodireto-com/image/upload/q_auto,f_auto/o_100,b_black/c_lfill,o_60,co_rgb:ffffff,b_rgb:000000/v1688160668/Others/Depoimentos/mayla_barbirato_xnoth4',
  },
  {
    text: 'Foi a primeira vez que eu e meu marido fizemos intercâmbio, e já voltamos conversando que vale muito a pena, e já temos uma lista de locais que queremos ir!',
    name: 'Thais Fernandes',
    age: 43,
    profession: 'Bióloga',
    product: 'Intercâmbio em Tampa - Flórida',
    videoURL: 'https://player.vimeo.com/video/873414552',
    thumbURL:
      'https://res.cloudinary.com/intercambiodireto-com/image/upload/q_auto,f_auto/o_100,b_black/c_lfill,o_60,co_rgb:ffffff,b_rgb:000000/v1688160668/Others/Depoimentos/thais_kiymws',
  },
  {
    text: 'Eu fui super bem tratada na cidade, desde acomodação, até o taxista que veio me buscar. [...] Não teria um destino melhor pra eu ter escolhido que não fosse Malta!',
    name: 'Bianca Cabral',
    age: 30,
    profession: 'Engenheira de meio ambiente',
    product: 'Intercâmbio em Malta',
    videoURL: 'https://player.vimeo.com/video/873420877',
    thumbURL:
      'https://res.cloudinary.com/intercambiodireto-com/image/upload/q_auto,f_auto/o_100,b_black/c_lfill,o_60,co_rgb:ffffff,b_rgb:000000/v1688160668/Others/Depoimentos/bianca_xnundl',
  },
  {
    text: 'A Inglaterra me surpreendeu, foi mais do que esperava! O povo inglês é muito acolhedor, a cidade é maravilhosa e a EC, minha escola, é excelente!',
    name: 'Alci Marques',
    age: 42,
    profession: 'Economista',
    product: 'Intercâmbio em Londres',
    videoURL: 'https://player.vimeo.com/video/873424774',
    thumbURL:
      'https://res.cloudinary.com/intercambiodireto-com/image/upload/q_auto,f_auto/o_100,b_black/c_lfill,o_60,co_rgb:ffffff,b_rgb:000000/v1688160668/Others/Depoimentos/alci_slcsy4',
  },
];

export const testimoniesEnglish = [
  {
    text: 'Eu estudei inglês a minha vida inteira, fiz vários cursos, mas sentia que nunca saía do nível intermediário […] com a Fluencypass eu senti muita evolução. Em 3 meses, venho me desenvolvendo muito na questão de vocabulário, tempos verbais e na comunicação.',
    name: 'Heloisa Pestana',
    age: 25,
    profession: 'Engenheira de alimentos',
    product: 'Inglês',
    videoURL: 'https://player.vimeo.com/video/838675582?h=d3513e3ad2',
    thumbURL:
      'https://res.cloudinary.com/intercambiodireto-com/image/upload/q_auto,f_auto/o_100,b_black/c_lfill,o_60,co_rgb:ffffff,b_rgb:000000/v1688160667/Others/Depoimentos/heloisa_pestana_dqq4yr.png',
  },
  {
    text: 'Eu percebi que eu precisava praticar, por que eu entendia bastante, meu listening era bom, mas eu não conseguia manter uma conversação [...] eu recomendo bastante a Fluencypass porque eu vi minha evolução.',
    name: 'Hugo Rosa',
    age: 29,
    profession: 'T.I',
    product: 'Inglês',
    videoURL: 'https://player.vimeo.com/video/838675620?h=23a969cf25',
    thumbURL:
      'https://res.cloudinary.com/intercambiodireto-com/image/upload/q_auto,f_auto/o_100,b_black/c_lfill,o_60,co_rgb:ffffff,b_rgb:000000/v1688160667/Others/Depoimentos/hugo_rosa_yprcbm.png',
  },
  {
    text: 'Eu era muito tímido, tinha vergonha de falar e aí eu me deparei com a Fluencypass… O método achei muito interessante, por isso, tranquei a escola que eu estava fazendo há 3 anos e me inscrevi […] O inglês pra mim era um peso, e agora tornou-se uma diversão.',
    name: 'João Luiz',
    age: 51,
    profession: 'Bibliotecário',
    product: 'Inglês',
    videoURL: 'https://player.vimeo.com/video/838675652?h=fbd7ca02b1',
    thumbURL:
      'https://res.cloudinary.com/intercambiodireto-com/image/upload/q_auto,f_auto/o_100,b_black/c_lfill,o_60,co_rgb:ffffff,b_rgb:000000/v1688160668/Others/Depoimentos/joao_luiz_ejwlyn.png',
  },
  {
    text: 'Eu sentia que só sabia regras gramaticais, mas não sabia aplicá-las, não sabia conversação [...] Em apenas 1 mês e meio estudando na Fluencypass eu já senti um avanço muito grande.',
    name: 'Thaís Nascimento',
    age: 28,
    profession: 'Estudante de letras',
    product: 'Inglês',
    videoURL: 'https://player.vimeo.com/video/838675757?h=d4df3a26f7',
    thumbURL:
      'https://res.cloudinary.com/intercambiodireto-com/image/upload/q_auto,f_auto/o_100,b_black/c_lfill,o_60,co_rgb:ffffff,b_rgb:000000/v1688160669/Others/Depoimentos/thais_nascimento_bmi6wj.png',
  },
  {
    text: 'Tentei aprender inglês ao longo da vida, e me sentia frustrado com a metodologia tradicional do país […] Com 3 meses na Fluencypass já deu pra sentir uma melhoria super drástica. Eu perdi o medo, a vergonha e a desconfiança em falar inglês, através de uma plataforma que me deu segurança psicológica para tal.',
    name: 'Rafael Rodrigues',
    age: 30,
    profession: 'Economista e controle de informação',
    product: 'Inglês',
    videoURL: 'https://player.vimeo.com/video/838675704?h=8d76f99c9e',
    thumbURL:
      'https://res.cloudinary.com/intercambiodireto-com/image/upload/q_auto,f_auto/o_100,b_black/c_lfill,o_60,co_rgb:ffffff,b_rgb:000000/v1688160667/Others/Depoimentos/rafael_rodrigues_vhgi1h.png',
  },
];

export const testimoniesHomeFull = [
  ...testimoniesEnglish,
  ...testimoniesAbroad,
];

export const abroadExpert = [
  {
    name: 'João Silva',
    order: 4,
    testimony:
      'A reunião foi crucial para entender o planejamento financeiro do meu intercâmbio. Agora, sinto que estudar fora é possível. Altamente recomendado para quem sonha com um intercâmbio!',
  },
  {
    name: 'Maria Fernandes',
    order: 2,
    testimony:
      'Indecisa sobre meu destino de intercâmbio, a reunião me ajudou a decidir e a economizar. Esclarecer dúvidas sobre vistos foi essencial. Os especialistas são incríveis!',
  },
  {
    name: 'Lucas Pereira',
    order: 3,
    testimony:
      'Impressionado com o conhecimento compartilhado, aprendi a economizar de forma eficaz. Tirar dúvidas em tempo real foi fantástico. Me sinto preparado para o intercâmbio. Muito obrigado!',
  },
  {
    name: 'Ana Cardoso',
    order: 4,
    testimony:
      'Fiquei surpresa ao descobrir que a reunião era gratuita! As dicas de economia que recebi valeriam ouro. Pensei que teria que pagar por essa consultoria, mas a economia que consegui com as orientações cobriria facilmente qualquer custo. Foi um investimento no meu futuro sem gastar nada. Estou mais do que pronta para meu intercâmbio agora!',
  },
];
