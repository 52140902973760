import { middlewareCall } from 'services/apiConfig';
import { createLog } from 'services/logService';

export const getPermission = async (permission) => {
  try {
    const response = await middlewareCall.get(
      `auth/permissions?permission=${permission}`
    );

    return response.data;
  } catch (error) {
    return createLog(error);
  }
};

export const getAllClaims = async () => {
  try {
    const response = await middlewareCall.get(`auth/claims`);
    return response.data;
  } catch (error) {
    return createLog(error);
  }
};
